export const white = '#ffffff';
export const whiteText = '#f2f2f2';
export const redText = '#ff5072';
export const greenText = '#56f6da';
export const primary = '#63CCE0';
export const secondaryLight = '#F9FAFB';
export const secondaryMideum = '#9CA3AF';
export const secondaryDark = '#323433';
export const accentLight = '#EFFAFC';
export const accentDark = '#3B7A86';
export const badgeBg = '#C9EEF5';
export const titleDark = '#212b36';
export const titleLight = '#f2f2f2';

export const subTitleDark = '#637381';
export const subTitleLight = '#e6e6e6';

export const descDark = '#637381';
export const descLight = '#e6e6e6';

export const buttonText = whiteText;
export const buttonBg = '#5ECEF1';

export const link = '#55cddd';

export const cardLight = '#ffffff';
export const cardDark = '#000000';

export const pageBgLight = '#f9fafc';
export const pageBgDark = '#191919';

export const chartColorOrange = 'rgb(239, 162, 78)';
export const chartColorYellow = 'rgb(243, 229, 84)';
export const chartTextColor = '#233238';

export const inputBorder = '#dee2e6';
export const formBg = '#dbdbdb';

// new design
export const lightText = '#FFFFFF';
export const darkText = '#323433';

export const primaryBtn = {
  background: primary,
  color: '#FFFFFF',
  hoverBackground: '#54adbe',
  hoverColor: '#FFFFFF',
  disabledBackground: 'rgba(31, 31, 31, 0.12)',
  disabledColor: '#818182',
}

export const secondaryBtn = {
  background: '#FFFFFF',
  color: primary,
  hoverBackground: primary,
  hoverColor: '#FFFFFF',
  disabledBackground: '#FFFFFF',
  disabledColor: '#818182',
  disabledBorderColor: 'rgba(31, 31, 31, 0.12)',
}

